/* Reset */
*,
::after,
::before,
[class*="ant-"],
[class*="ant-"] *,
[class*="ant-"] ::after,
[class*="ant-"] ::before,
[class^="ant-"],
[class^="ant-"] *,
[class^="ant-"] ::after,
[class^="ant-"] ::before {
  box-sizing: border-box;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: 500;
}
dl,
ol,
p,
ul {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[data-original-title],
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type="number"],
input[type="password"],
input[type="text"],
textarea {
  -webkit-appearance: none;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote,
figure {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  text-decoration: none;
  background-color: transparent;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #287a99;
}
a:active {
  color: #4eb3d2;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(255, 255, 255, 0.3);
  cursor: not-allowed;
  pointer-events: none;
}
code,
kbd,
pre,
samp {
  font-size: 1em;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
    monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
[role="button"],
a,
area,
button,
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(255, 255, 255, 0.45);
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}

button,
.ant-btn-default {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  box-shadow: none;
  background: none;
}

/* End of Reset */

body {
  background: white !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  color: black;
  margin: 0;

  /* Set in the antd theme provider but storybook isn't aware of that with antd components that use portals */
  font-family: Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-layout-footer {
  padding: 0;
  font-size: 14px;
}

.header-bar {
  display: none;
}

.ant-menu-horizontal {
  border: 0px;
}

.ant-menu-title-content::before {
  border: 0px;
  outline: 0px;
}

.ant-menu-item-selected .ant-menu-title-content {
  color: var(--menuSelected) !important;
}

.ant-menu-horizontal,
.ant-menu-item::after,
.ant-menu-submenu::after {
  border: none !important;
}

.medium-title {
  font-size: 26px;
}

.medium-title-two {
  font-size: 22px;
}

.bold {
  font-weight: bold;
}

.ant-menu-item {
  border-radius: 12px;
}

.ant-menu-item a {
  color: #808191;
}

.ant-menu-item-selected span {
  border: 0px !important;
}

.ant-menu-item-selected a {
  color: white !important;
}

.ant-layout {
  background-color: transparent;
}

.mobile-quest-cta {
  height: 87px;
  border: 2px solid var(--color-gray-350);
  border-radius: 8px;
  margin-top: 30px !important;
}

.poll-form-section {
  /* margin-top: 10px; */
  padding: 0px 5%;
  /* background-color: rgba(0, 0, 0, 0.8); */
  /* border-radius: 10px !important; */
  text-align: left;
}

.poll-form-element {
  margin: 24px 0px;
}

.poll-form-element-label {
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.poll-form-element-label img {
  max-width: 100% !important;
}

.close-button {
  align-items: center;
  padding: 8px 16px;
  width: auto;
  height: 40px;
  border-radius: 8px;
  max-width: 200;
  margin-bottom: 0;
  margin-left: "auto";
  margin-right: 10;
}

.ant-select-selection-item {
  color: white !important;
}

.ant-select-item-option {
  background: none !important;
}

.ant-select-item-option-content {
  color: white;
}

.ant-select-item-option-active {
  background-color: rgba(255, 255, 255, 0.12) !important;
}

/* QUEST DISPLAY CARD STYLING */
.quest-video-player {
  width: 100% !important;
  height: 100% !important;
  border-radius: 12px !important;
}

.quest-video-player video {
  border-radius: 12px !important;
  max-height: 250px !important;
}

.ant-upload-text {
  color: white !important;
}

.ant-upload-list {
  color: white !important;
}

.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item-error
  .ant-upload-list-item-name,
:where(.css-dev-only-do-not-override-1me4733).ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item-error
  .ant-upload-icon
  .anticon {
  color: white;
}

.ant-upload-list-item-actions svg {
  color: white !important;
}

.display-quest-header {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
}

.preview-quest-header {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 50px;
  color: var(--inputFieldText);
}

.mobile-cta-affixed {
  display: block;
  position: fixed;
  width: 100%;
  z-index: 5;
}

.quest-submission-preview-container {
  overflow: hidden !important;
  width: 48%;
  height: 100px;
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  margin: 1%;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.quest-submission-preview-container .quest-submission-preview {
  height: 100px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.quest-submission-preview {
  width: 100%;
  height: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* filter: grayscale(100%); */
}

.quest-submission-preview-blurred {
  /* backdrop-filter: blur(14px); */
  /* -webkit-backdrop-filter: blur(14px); */
  -webkit-filter: blur(14px);
  filter: blur(14px);
  /*
    -webkit-filter: blur(14px) grayscale(100%);
    filter: blur(14px) grayscale(100%);
    */
}

.quest-submission-preview.background {
  filter: blur(2090px) !important;
}

.quest-submission-preview img {
  backdrop-filter: blur(20px);
  border: 10px solid blue;
  overflow: auto;
  padding: 0.5%;
}

.fixed-top {
  top: 0;
}

.fixed-bottom {
  bottom: 0;
}

.mobile-cta-affixed button {
  margin: 0px !important;
}

.submit-description-text {
  text-transform: uppercase;
  color: var(--color-cream, #fef6f3);
  font-family: Arial;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.ant-message-notice-content {
  background: white !important;
}

.ant-statistic-content-value {
  color: white;
  font-size: 34px;
  font-weight: 700;
}

.ant-input-number-input {
  color: white !important;
}

.ant-picker input {
  color: white !important;
}

.ant-picker-input {
  color: white !important;
}

.ant-picker-input > input::placeholder {
  color: rgba(255, 255, 255, 0.25) !important;
}

.ant-modal-close-x {
  text-align: left !important;
  margin-left: 3px !important;
}

.submission-modal {
  background: white !important;
}

.submit-section {
  height: 100%;
  width: 100%;
  min-height: 1000px;
  position: absolute !important;
  background-color: blue;
}

.ant-space {
  gap: 0px !important;
}

.admin-quest-display {
  width: 100%;
  height: auto;
  word-wrap: break-word;
  text-align: left;
  border-radius: 12px;
  border: 0;
  margin: auto;
  padding: 28px 24px 24px 24px;
}

.new-quest-button {
  align-items: center;
  padding: 8px 16px;
  width: 126px;
  height: 40px;
  background: var(--nextPreviewButton);
  border-radius: 8px;
  max-width: 200;
  border: 0;
  margin-left: 0px 10px 0px auto;
  font-weight: bold;
}

.next-preview-button {
  align-items: center;
  padding: 8px 16px;
  width: fit-content;
  height: 40px;
  background: var(--nextPreviewButton);
  border-radius: 8px;
  border: 0;
  margin-left: 0px 10px 0px auto;
  font-weight: bold;
  color: white;
}

.next-preview-button:hover {
  background: var(--nextPreviewButton) !important;
}

.new-quest-button:hover {
  background: var(--nextPreviewButton) !important;
}

.create-quest-content-section {
  height: auto;
  border-radius: 8px;
  padding: 18px 12px;
}

/* DateTime Picker */
.react-datetime-picker {
  height: 48px;
}

.react-datetime-picker__inputGroup {
  padding: 0px 12px 0px 12px !important;
}

.react-datetime-picker__wrapper {
  border: 2px solid #353945;
  border-radius: 8px;
}

.react-calendar__month-view__days__day {
  color: black !important;
}

.react-calendar__navigation__label__labelText {
  color: black !important;
}

.react-calendar__navigation__arrow {
  color: black !important;
}

.react-datetime-picker abbr {
  color: black !important;
}

.datetime-picker {
  width: 205px !important;
  text-align: center;
}
/* End DateTime Picker */

.right {
  text-align: right;
}

.upload-drag-and-drop {
  height: 900px;
}

.upload-drag-and-drop-storyworld {
  height: 100px !important;
}

.ant-upload.ant-upload-drag {
  height: 630px !important;
  width: 100%;
  border-radius: 8px;
  background: transparent;
  border: 2px dashed var(--color-gray-50) !important;
}

.ant-upload-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  gap: 8px;
  width: 210px;
  height: 48px;
  left: 0px;
  top: 0px;
  font-size: 12px !important;
  font-weight: 600 !important;
  margin: auto !important;
  color: #575b62 !important;
  margin-bottom: 8px !important;
  background: var(--textAreaColor);
  border-radius: 12px;
}

.ant-upload-hint {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 24px !important;
  text-align: center;
  color: var(--inputFieldText);
}

.chevron-button {
  border: 0px !important;
  margin: auto;
  /* color: rgba(255, 255, 255, 0.85) !important; */
}

.column-title {
  margin: auto !important;
}

.edit-poll-date-div {
  display: initial;
  margin-top: initial;
}

.edit-poll-title-div {
  max-width: 75%;
  width: 75%;
  flex: initial;
}

.new-poll-button {
  display: none;
}

.edit-poll-date-div {
  display: inline-table;
  margin-top: 20px;
}

.edit-poll-title-div {
  max-width: 100%;
  width: 100%;
  flex: 100%;
}

.create-quest-input-field {
  background: var(--color-gray-300);
  border: 0px;
  vertical-align: top;
  font-size: 12px;
  font-family: inherit;
  color: white;
  font-weight: 700;
  padding: 14px;
  line-height: 24px;
  border-radius: 8px;
}

.create-quest-input-field .ql-container {
  border: 0px !important;
}

.create-quest-input-field .ql-toolbar {
  border: 0px !important;
}

.submit-countdown span {
  color: var(--color-cream);
  text-align: center;
  font-family: Arial;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-top: 13px;
}

.publish-quest-button {
  background-color: var(--color-purple) !important;
}

.publish-quest-button:hover {
  background-color: var(--color-purple) !important;
}

.ant-avatar {
  background: var(--color-gray-350);
}

.react-datetime-picker__inputGroup__hour {
  width: 17px !important;
  text-align: right;
}

.react-datetime-picker__inputGroup__minute {
  width: 17px !important;
}

.modal {
  max-width: 720px;
  width: 100% !important;
}

.modal h3 {
  font-weight: bold !important;
}

.modal .ant-modal-header {
  border: none;
}

.modal .ant-modal-footer {
  border: none;
}

.ant-modal-footer {
  text-align: center !important;
  margin-bottom: 30px;
  margin-top: 0px !important;
}

.ant-modal-footer .ant-btn {
  color: inherit !important;
}

.create-storyworld-button {
  width: 168px !important;
  font-weight: 600;
}

.modal .ant-upload-hint {
  margin: auto;
  line-height: 17px !important;
}

.modal .ant-upload.ant-upload-drag {
  height: 200px !important;
  width: 100%;
  border-radius: 8px;
  background: transparent;
  border: 2px dashed #4f505b;
}

.create-storyworld-modal .ant-upload.ant-upload-drag {
  height: 164px !important;
  width: 100%;
  border-radius: 8px;
  background: transparent;
  border: 2px dashed #4f505b;
}

.create-storyworld-title-field {
  padding-left: 26px;
  font-family: "Arial";
  font-weight: 500 !important;
  color: white;
}

.create-storyworld-title-field::placeholder {
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: 600 !important;
}

.close-button:hover {
  /* color: var(--hoverColor) !important; */
  /* border-color: var(--hoverColor) !important; */
}

.close-button:focus {
  /* color: rgba(255, 255, 255, 0.85) !important; */
  border-color: #6f6c6c !important;
}

.poll-column {
  display: grid;
  height: 100%;
  margin: auto;
}

.ant-select-arrow {
  color: #777e91 !important;
  margin-top: -10px !important;
}

.votes-text {
  font-weight: bold;
  color: var(--hoverColor);
}

.create-poll-votes-select {
  width: 55px;
  margin-top: -4px;
  margin-left: 14px;
}

.react-datetime-picker--disabled {
  background: transparent !important;
}

.admin-quest-display-poll-image {
  object-fit: contain;
  width: 112px;
  height: 63px;
  margin: auto;
  border-radius: 8px;
}

.wifi-icon {
  height: 8px;
  margin-right: 10px;
  margin-bottom: 2px;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: var(--hoverColor);
}

a {
  color: var(--hoverColor);
}

a:hover {
  color: var(--hoverColor);
}

.ant-menu-light {
  background: transparent !important;
}

.ant-menu-item-only-child {
  padding-left: 14px !important;
}

.ant-menu-submenu-title {
  padding-left: 14px !important;
}

.ant-menu-title-content a {
  color: white !important;
}

.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: white !important;
}

.ant-menu-submenu-arrow::after {
  background: white !important;
}

.ant-menu-submenu-arrow::before {
  color: white !important;
}

.side-nav-big-header {
  margin: auto;
  font-weight: bold;
  color: white !important;
  font-size: 15px;
}

.side-nav-big-menu-item {
  margin-bottom: 12px !important;
}

.ant-menu-inline {
  background: transparent !important;
  border-inline-end: 0px !important;
  font-family: "Arial";
}

.create-storyworld-modal .ant-modal-close {
  display: none;
}

.react-player__play-icon {
  display: block !important;
}

.text {
  height: 100%;
  width: 100%;
  color: white;
  font-size: 16px;
  border-radius: 12px;
  margin: auto;
  opacity: 0.2;
  position: absolute;
  background: var(--hoverColor);
  vertical-align: middle;
}

.ant-modal {
  overflow: hidden !important;
}

.ant-modal-wrap {
  overflow: hidden !important;
}

.ant-modal-mask {
  overflow: hidden !important;
}

.submission:hover {
  /* border: 2px solid var(--hoverColor) !important; */
  border-radius: 12px;
  /* box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important; */
}

.ant-btn[disabled]:hover {
  background: transparent;
}

.ant-spin-dot-item {
  background-color: var(--hoverColor) !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: auto;
  width: auto;
}

.user-display-result {
  margin-top: 10px !important;
}

.user-display-result .vision-holder-name {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 0px;
  /* identical to box height, or 325% */
  color: var(--hoverColor);
  margin: auto;
  display: contents !important;
}

.user-display-result .user-display-div {
  display: contents !important;
}

.storyworld-button {
  width: auto;
  height: 26px;
  border-radius: 65px;
  font-size: 8pt;
  padding: 0px 10px;
  border: 0;
  font-weight: bold;
  color: var(--hoverColor);
  background: var(--color-gray-400);
}

.storyworld-button:disabled {
  color: var(--hoverColor) !important;
  background: var(--color-gray-400) !important;
}

.storyworld-button:active {
  color: var(--hoverColor) !important;
  background: var(--color-gray-400) !important;
}

.storyworld-button:hover {
  color: var(--hoverColor) !important;
  background: var(--color-gray-400) !important;
}

.storyworld-button:focus {
  color: var(--hoverColor) !important;
  background: var(--color-gray-400) !important;
}

.submission-success-countdown span {
  font-size: 11px;
  color: var(--menuSelected);
}

.link {
  color: var(--hoverColor);
  font-weight: bold !important;
}

.subscribed {
  color: var(--menuSelected) !important;
}

.subscribed:focus {
  color: var(--menuSelected) !important;
}

.subscribed:active {
  color: var(--menuSelected) !important;
}

.subscribed:hover {
  color: var(--menuSelected) !important;
}

.margin-auto {
  margin: auto !important;
}

.storyworld-card-selector .ant-image {
  width: 100% !important;
}

.ant-modal-body .ant-image {
  width: 100%;
}

.big-community-entry .submission-image {
  height: 190px !important;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

.animate-pulse {
  animation: pulse-opacity 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes pulse-opacity {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.transition-colors {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.safari-keyboard .app-container {
  bottom: 270px;
}

.ant-dropdown-menu {
  border-radius: 8px;
  box-shadow: 0px 7px 16px 0px rgba(22, 24, 31, 0.7) !important;
  background: white !important;
  padding: 6px !important;
  color: black !important;
}

.spinner {
  width: 18px;
  height: 18px;
  animation: rotation 2s infinite linear;
  margin: auto;
  margin-top: 2px;
}

.ant-message {
  z-index: 9999999 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #353945 !important;
  border-radius: 12px;
}

.ant-message-success {
  color: black;
}

.ant-modal-wrap {
  overflow: auto !important; /* Fix for modal scroll */
}
