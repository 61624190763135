.report-notification {
  color: var(--color-cream-base);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.2px;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid #9c86e1;
  background: var(--color-gray-300);
  box-shadow: 0px 7px 16px 0px rgba(22, 24, 31, 0.71);

  .ant-notification-notice-close {
    top: 6px;
    right: 6px;
  }
}

.error-notification {
  color: var(--color-cream-base);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.2px;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid #da5149;
  background: var(--color-gray-300);
  box-shadow: 0px 7px 16px 0px rgba(22, 24, 31, 0.71);

  .ant-notification-notice-close {
    top: 6px;
    right: 6px;
  }
}

.admin-notification {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.2px;
  padding: 8px 20px;
  border-radius: 8px;
  border: 1px solid #da5149;
  background: var(--color-gray-300);
  box-shadow: 0px 7px 16px 0px rgba(22, 24, 31, 0.71);

  .ant-notification-notice-close {
    top: 6px;
    right: 6px;
  }
}

.creator-message-notification {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.2px;
  padding: 8px 20px;
  border-radius: 8px;
  border: 1px solid #505dff;
  background: var(--color-gray-300);
  box-shadow: 0px 7px 16px 0px rgba(22, 24, 31, 0.71);

  .ant-notification-notice-close {
    top: 6px;
    right: 6px;
  }
}
