/* Poppins Import */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  src:
    local(""),
    url("fonts/Poppins/Poppins-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: bold;
  src:
    local(""),
    url("fonts/Poppins/Poppins-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src:
    local(""),
    url("fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  src:
    local(""),
    url("fonts/Poppins/Poppins-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src:
    local(""),
    url("fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  src:
    local(""),
    url("fonts/Poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  src:
    local(""),
    url("fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 200;
  src:
    local(""),
    url("fonts/Poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src:
    local(""),
    url("fonts/Poppins/Poppins-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src:
    local(""),
    url("fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  src:
    local(""),
    url("fonts/Poppins/Poppins-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src:
    local(""),
    url("fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  src:
    local(""),
    url("fonts/Poppins/Poppins-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src:
    local(""),
    url("fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src:
    local(""),
    url("fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  src:
    local(""),
    url("fonts/Poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  src:
    local(""),
    url("fonts/Poppins/Poppins-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 100;
  src:
    local(""),
    url("fonts/Poppins/Poppins-ThinItalic.ttf") format("truetype");
}
/* End of Poppins Import */

/* Roboto Mono Import */

@font-face {
  font-family: "Roboto Mono";
  src: url("fonts/Roboto_Mono/RobotoMono-VariableFont_wght.ttf")
    format("truetype");
}
/* End of Roboto Mono Import */

/* Hoss Sharp Import */

@font-face {
  font-family: "Hoss Sharp";
  font-style: normal;
  font-weight: 100;
  src:
    local(""),
    url("fonts/Hoss Sharp/HossSharp-Medium.woff2") format("woff2"),
    url("fonts/Hoss Sharp/HossSharp-Medium.woff") format("woff");
}

@font-face {
  font-family: "Hoss Sharp";
  font-style: normal;
  font-weight: 500;
  src:
    local(""),
    url("fonts/Hoss Sharp/HossSharp-Bold.woff2") format("woff2"),
    url("fonts/Hoss Sharp/HossSharp-Bold.woff") format("woff");
}

@font-face {
  font-family: "Hoss Sharp";
  font-style: normal;
  font-weight: 600;
  src:
    local(""),
    url("fonts/Hoss Sharp/HossSharp-Bold.woff2") format("woff2"),
    url("fonts/Hoss Sharp/HossSharp-Bold.woff") format("woff");
}

@font-face {
  font-family: "Hoss Sharp";
  font-style: normal;
  font-weight: 700;
  src:
    local(""),
    url("fonts/Hoss Sharp/HossSharp-Black.woff2") format("woff2"),
    url("fonts/Hoss Sharp/HossSharp-Black.woff") format("woff");
}
/* End of Hoss Sharp Import */

/* DM Sans Import */
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 100;
  src:
    local(""),
    url("fonts/DM_Sans/static/DMSans-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 100;
  src:
    local(""),
    url("fonts/DM_Sans/static/DMSans-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 200;
  src:
    local(""),
    url("fonts/DM_Sans/static/DMSans-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 200;
  src:
    local(""),
    url("fonts/DM_Sans/static/DMSans-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 300;
  src:
    local(""),
    url("fonts/DM_Sans/static/DMSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 300;
  src:
    local(""),
    url("fonts/DM_Sans/static/DMSans-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  src:
    local(""),
    url("fonts/DM_Sans/static/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 400;
  src:
    local(""),
    url("fonts/DM_Sans/static/DMSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  src:
    local(""),
    url("fonts/DM_Sans/static/DMSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 500;
  src:
    local(""),
    url("fonts/DM_Sans/static/DMSans-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  src:
    local(""),
    url("fonts/DM_Sans/static/DMSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 600;
  src:
    local(""),
    url("fonts/DM_Sans/static/DMSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  src:
    local(""),
    url("fonts/DM_Sans/static/DMSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 700;
  src:
    local(""),
    url("fonts/DM_Sans/static/DMSans-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 800;
  src:
    local(""),
    url("fonts/DM_Sans/static/DMSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 800;
  src:
    local(""),
    url("fonts/DM_Sans/static/DMSans-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  src:
    local(""),
    url("fonts/DM_Sans/static/DMSans-Black.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: bold;
  src:
    local(""),
    url("fonts/DM_Sans/static/DMSans-BlackItalic.ttf") format("truetype");
}
/* End of DM Sans Import */

/* Urbanist Import */
@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 100;
  src:
    local(""),
    url("fonts/Urbanist/Urbanist-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-style: italic;
  font-weight: 100;
  src:
    local(""),
    url("fonts/Urbanist/Urbanist-Italic-VariableFont_wght.ttf")
      format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 200;
  src:
    local(""),
    url("fonts/Urbanist/Urbanist-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-style: italic;
  font-weight: 200;
  src:
    local(""),
    url("fonts/Urbanist/Urbanist-Italic-VariableFont_wght.ttf")
      format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 300;
  src:
    local(""),
    url("fonts/Urbanist/Urbanist-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-style: italic;
  font-weight: 300;
  src:
    local(""),
    url("fonts/Urbanist/Urbanist-Italic-VariableFont_wght.ttf")
      format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  src:
    local(""),
    url("fonts/Urbanist/Urbanist-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-style: italic;
  font-weight: 400;
  src:
    local(""),
    url("fonts/Urbanist/Urbanist-Italic-VariableFont_wght.ttf")
      format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 500;
  src:
    local(""),
    url("fonts/Urbanist/Urbanist-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-style: italic;
  font-weight: 500;
  src:
    local(""),
    url("fonts/Urbanist/Urbanist-Italic-VariableFont_wght.ttf")
      format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 600;
  src:
    local(""),
    url("fonts/Urbanist/Urbanist-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-style: italic;
  font-weight: 600;
  src:
    local(""),
    url("fonts/Urbanist/Urbanist-Italic-VariableFont_wght.ttf")
      format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  src:
    local(""),
    url("fonts/Urbanist/Urbanist-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-style: italic;
  font-weight: 700;
  src:
    local(""),
    url("fonts/Urbanist/Urbanist-Italic-VariableFont_wght.ttf")
      format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 800;
  src:
    local(""),
    url("fonts/Urbanist/Urbanist-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-style: italic;
  font-weight: 800;
  src:
    local(""),
    url("fonts/Urbanist/Urbanist-Italic-VariableFont_wght.ttf")
      format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: bold;
  src:
    local(""),
    url("fonts/Urbanist/Urbanist-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-style: italic;
  font-weight: bold;
  src:
    local(""),
    url("fonts/Urbanist/Urbanist-Italic-VariableFont_wght.ttf")
      format("truetype");
}
/* End of Urbanist Import */

/* Start of Symbola Import */
@font-face {
  font-family: "Symbola";
  font-style: normal;
  font-weight: normal;
  src:
    local(""),
    url("fonts/Symbola/Symbola.ttf") format("truetype");
}
/* End of Symbola Import */

:root {
  /* fonts */
  --font-primary: Poppins;
  --font-secondary: Poppins;
}
